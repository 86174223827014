import { useEffect, useState } from "react";
import { useLoginMutation } from "services/webServer";
import { useCheckPasswordlessAccountMutation } from "services/webServer";
import { usePasswordlessLoginMutation } from "services/webServer";
import { usePasswordlessAuthenticateMutation } from "services/webServer";
import { errorHandle } from "utils/errorHandle";
import validate from "validate.js";
import get from "lodash/get";
import { getCodeFromError } from "scripts/utils/handleErrors";
import { getUrlVars } from "utils/url";
import useGetCrmSessionHook from "Hooks/CrmSessionHook";
import { gameServerApi } from "services/gameServerV2";

const schema = {
    email: {
        presence: { allowEmpty: false, message: "is required" },
        email: true,
        length: {
            maximum: 64,
        },
    },
    password: {
        presence: { allowEmpty: true, message: "is required" },
        length: {
            maximum: 128,
        },
    },
};

const useLoginHook = () => {
    const [login] = useLoginMutation();
    const [checkPasswordLess] = useCheckPasswordlessAccountMutation();
    const [passwordLessLogin] = usePasswordlessLoginMutation();
    const [passwordLessAuthenticate] = usePasswordlessAuthenticateMutation();
    const { getCrmSession } = useGetCrmSessionHook();

    const [formState, setFormState] = useState<any>({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        state: "email",
        emailId: null,
    });
    const [isPasswordLess, setIsPasswordLess] = useState<boolean>(true);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [emailId, setEmailId] = useState<string>("");

    useEffect(() => {
        // if (formState.state === "email") {
        //     setFormState((formState: any) => ({
        //         ...formState,
        //         isValid: true,
        //         errors: {}
        //     }));
        //     return;
        // }
        // const errors = validate(formState.values, schema);
        // setFormState((formState: any) => ({
        //     ...formState,
        //     isValid: errors ? false : true,
        //     errors: errors || {},
        // }));
        const errors = validate(formState.values, schema);
        let isValid = true;
        if (formState.state === "email") {
            if (errors && errors.email) {
                isValid = false;
            }
        } else {
            isValid = !errors;
        }
        setFormState((formState: any) => ({
            ...formState,
            isValid,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleChange = (event: any) => {
        event.persist();
        setFormState((formState: any) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === "checkbox"
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
            state:
                event.target.name === "email" &&
                    formState.values.email !== event.target.value
                    ? "email"
                    : formState.state,
        }));
    };

    const handleAddEmail = (event: any) => {
        event.preventDefault();

        if (!formState.values.email || formState.values.email.length === 0) {
            setFormState((formState: any) => ({
                ...formState,
                touched: {
                    ...formState.touched,
                    email: true,
                },
                errors: {
                    ...formState.errors,
                    email: ["Email is required"],
                },
            }));
            return;
        } else {
            const errors = validate(formState.values, schema);
            if (errors && errors.email && errors.email.length) {
                setFormState((formState: any) => ({
                    ...formState,
                    touched: {
                        ...formState.touched,
                        email: true,
                    },
                    errors: {
                        ...formState.errors,
                        email: ["Email format invalid"],
                    },
                }));
                return;
            }
        }

        setIsProcessing(true);
        checkPasswordLess({
            variables: {
                email: formState.values.email,
            },
        })
            .then((result) => {
                if (result.data && result.data.checkPasswordlessAccount) {
                    setIsPasswordLess(
                        get(
                            result,
                            "data.checkPasswordlessAccount.passwordless"
                        )
                    );
                    setFormState({
                        ...formState,
                        state: get(
                            result,
                            "data.checkPasswordlessAccount.passwordless"
                        )
                            ? "token"
                            : "password",
                    });
                    if (
                        get(
                            result,
                            "data.checkPasswordlessAccount.passwordless"
                        )
                    ) {
                        passwordLessLogin({
                            variables: { email: formState.values.email },
                        }).then((result) => {
                            setEmailId(
                                get(
                                    result,
                                    "data.passwordlessLogin.userEmail.id"
                                )
                            );
                        });
                    }
                }
            })
            .catch((err) => {
                errorHandle(err);
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };

    const handleSignIn = (event: any) => {
        event.preventDefault();
        setIsProcessing(true);
        if (isPasswordLess) {
            handleSignInPasswordLess();
        } else {
            handleSignInPassword();
        }
    };

    const redirectAfterLogin = async () => {
        setIsProcessing(true)
        await getCrmSession(false);
        // await gameServerApi.app.getGameToken();
        const params = getUrlVars();
        if (
            params &&
            params.redirect &&
            params.redirect.includes(window.location.host)
        ) {
            // console.log('nlnlnlnlnlnln', window.location.search.replace('?redirect=', ''))
            window.location.href = window.location.search.replace('?redirect=', '');
        } else {
            window.location.href = "/";
        }
    };

    const handleSignInPassword = () => {
        login({
            variables: {
                username: formState.values.email,
                password: formState.values.password,
            },
        })
            .then(async (result) => {
                redirectAfterLogin();
            })
            .catch((err) => {
                handleLoginError(err);
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };

    const handleSignInPasswordLess = () => {
        passwordLessAuthenticate({
            variables: {
                emailId: emailId,
                token: formState.values.password,
            },
        })
            .then(async (result) => {
                redirectAfterLogin();
            })
            .catch((err) => {
                handleLoginError(err);
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };

    const handleLoginError = (e: Error) => {
        const code = getCodeFromError(e);
        if (code === "CREDS") {
            if(e.message.includes('Incorrect emailId/token')){
                Object.assign(formState, {
                    errors: { login: "Incorrect email or token." },
                });
            } else {
                Object.assign(formState, {
                    errors: { login: "Incorrect password." },
                });
            }
        } else if (code === "LOCKD") {
            Object.assign(formState, {
                errors: {
                    login: "Too many failed login attempts. This account is temporarily locked. Please try again in 5 minutes.",
                },
            });
        } else if (code === "23502") {
            Object.assign(formState, {
                errors: {
                    login: "Login with temporary code error. Please check temporary code and try again.",
                },
            });
        } else {
            Object.assign(formState, {
                errors: { login: "Hmm that's not right. Please try again" },
            });
        }
        setFormState(formState);
    };

    const hasError = (field: string) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    return {
        handleSignIn,
        hasError,
        formState,
        handleChange,
        isProcessing,
        handleAddEmail,
    };
};

export default useLoginHook;
