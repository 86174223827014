import { Grid } from "@mui/material";
import CardSimple from "Components/CardSimple";
import VerticalBarChart from "Components/WorkoutOverViewPage/WorkoutVerticalChart/VerticalBarChart";
import React, { useContext, useEffect, useMemo, useState } from "react";
import TabsChart from "Components/base/Tabs";
import style from "./WorkoutVerticalChart.module.scss";
import { DATE_NAMES } from "consts/dateTime";
import useStatisticWorkoutSummaryHook from "Hooks/StatisticWorkoutHook";
import { CONSTANTS } from "consts";
import { useCheckAppHook } from "Hooks/useCheckApp.hook";
import { UserAppContext } from "contexts";
import { convertMeterToKilometer, convertMeterToMile } from "utils/convertMeasure";

const formatDataChart = (dataWorkout7Day: any) => {
    let dates = [];
    // let workouts = [];
    let metters = [];
    let times = [];
    let outputs = [];
    for (let i = 0; i < dataWorkout7Day.length; i++) {
        const item = dataWorkout7Day[i];
        const DateObj = new Date(item.date + " 00:00:00");
        const dateItem = `${DateObj.getDate()}`;
        const dateName = `${DATE_NAMES[DateObj.getDay()]}`;
        dates.push([dateName, dateItem]);
        // workouts.push(item.workouts);
        metters.push(item.totalMeters);
        times.push(Math.floor((item.totalTime / 60) * 100) / 100);
        outputs.push(item.totalOutput);
    }
    return {
        dates: dates,
        metters: metters,
        times: times,
        outputs: outputs,
    };
};
const WorkoutVerticalChart = () => {

    const { workoutState } = useContext(UserAppContext)

    // const { appId } = useCheckAppHook()

    const distanceTab = useMemo(() => {
        switch (workoutState.currentUnit) {
            case "miles":
                return { active: 2, label: "MILES", value: "metters", unit: "Miles" }
            case "kilometers":
                return { active: 2, label: "KILOMETERS", value: "metters", unit: "Kilometers" }
            default:
                return { active: 2, label: "METERS", value: "metters", unit: "Meters" }

        }
    }, [workoutState.currentUnit])

    const [dataChart, setDataChart] = useState({
        dates: [],
        // workouts,
        metters: [],
        times: [],
        outputs: [],
    });

    const tabOptions = [
        {
            active: 0,
            label: "OUTPUT",
            value: "outputs",
            unit: "Kilojoules",
        },
        { active: 1, label: "TIME", value: "times", unit: "Minutes", },
        distanceTab
    ];

    const [activeTab, setActiveTab] = useState(tabOptions[0]);
    const {
        data: workoutLast7DaysData,
        getStatisticWorkoutSummary,

    } = useStatisticWorkoutSummaryHook();

    useEffect(() => {
        getStatisticWorkoutSummary(CONSTANTS.TIME_RANGE_ID.day7);
    }, []);

    useEffect(() => {
        if (workoutLast7DaysData) {
            let formatedData: any[] = [...workoutLast7DaysData];
            switch (workoutState.currentUnit) {
                case "miles":
                    formatedData = formatedData.map((item: any) => {
                        return { ...item, totalMeters: Number(convertMeterToMile(item.totalMeters, 100, true)) }
                    })
                    break;
                case "kilometers":
                    formatedData = formatedData.map((item: any) => {
                        return { ...item, totalMeters: Number(convertMeterToKilometer(item.totalMeters, 100, true)) }
                    })
                    break;
                default:
                    break;
            }

            setDataChart(
                //@ts-ignore
                formatDataChart(formatedData)
            );
        }
    }, [workoutLast7DaysData, workoutState.currentUnit]);

    useEffect(() => {
        const newCurrentTab = tabOptions.find(tab => { return tab.active === activeTab.active })
        if (newCurrentTab) {
            setActiveTab(newCurrentTab)
        }
    }, [workoutState.currentUnit])

    const handleChangeTab = (currentTab: any) => {
        setActiveTab(currentTab);
    };

    return (
        <div className={style["WorkoutVerticalChart"]}>
            <CardSimple
                title={
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems="center"
                        spacing={2}
                        className={style["titleCardSimple"]}
                    >
                        <Grid item>
                            <div className="titleCard">LAST 7 DAYS</div>
                        </Grid>
                        <Grid item>
                            <TabsChart
                                options={tabOptions}
                                handleChangeTab={handleChangeTab}
                            />
                        </Grid>
                    </Grid>
                }
            >
                {dataChart && (
                    <VerticalBarChart
                        //@ts-ignore
                        data={dataChart[activeTab["value"]]}
                        yData={{ label: activeTab["unit"],}}
                        xData={dataChart.dates}
                        nameChart={activeTab["label"]}
                        keyChart={activeTab['value']}
                    // initData={[0, 0, 0, 0, 0, 0, 0]}
                    // activeTab={activeTab}
                    />
                )}
            </CardSimple>
        </div>
    );
};

export default WorkoutVerticalChart;
